import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const HaroldHughes = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/harold-hughes-headshot.jpg"
                alt="Harold Hughes" />
        name="Harold Hughes"
        position="Chief Executive Officer"
  >
    <p>
        Harold Hughes is the founder & CEO of Bandwagon, a South Carolina-based
        experience technology company. Under his leadership, Bandwagon has
        raised more than $3M in venture capital and successfully acquired
        IdealSeat, Inc. in 2020. Harold has had his ideas validated as a
        graduate of the Founder Institute Accelerator (and now serves as
        Director for their Austin chapter), one of the inaugural companies in
        Google for Startups Black Founders Exchange Program, and most recently,
        the inaugural cohort of the IBM Blockchain Accelerator in 2019.
    </p>
    <p>
        A man of Alpha Phi Alpha Fraternity, Inc., Harold is actively involved
        in his community, participating on the Board of Directors for BEAM, a
        non-profit that focuses on the advancement of women entrepreneurs as
        they grow their companies as well as a founding team member of Amass,
        an end-to-end wealth building platform for Black Americans. To that end,
        Harold is also an active angel investor, investing in women, people of
        color, and Black founder-led companies. Most notably, he has invested in
        Partake Foods, the Jay-Z-backed CPG company making allergy-friendly
        snacks, as well as companies like Goodr, Chipper, Journey Foods,
        Republic, and Stix.
    </p>
    <p>
        Harold Hughes is a graduate of Clemson University, where he received
        Bachelor’s degrees in both Economics (B.A.) and Political Science
        (B.A.). As a “Triple Tiger”, he also completed his MBA at Clemson before
        pursuing a graduate certificate in Innovation & Entrepreneurship at
        Stanford University.
    </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        He started his educational journey abroad when his parents sent him to
        Jamaica to attend kindergarten at age 4.
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        I’m really proud of what it took to start this company. It has taken so much
        from so many people to get to this point; so, I’m really thankful for the
        people who believed in me when we were just getting started.
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default HaroldHughes
